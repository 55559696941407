<template>

  <div>
    <div style="height: 0; opacity: 0; overflow: hidden; min-width:790px; width:790px; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
        >
          <div
            class="card__inner"
            style="height: 1080px;"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="width:60px; height:60px;"
                      :src="logoSrc"
                      alt="Logo"
                    >
                  </div>
                </div>
                <div
                  class="px-2"
                  style="min-width: fit-content; width: fit-content;"
                >
                  <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                    Purchase Requisition form (PRF)
                  </h4>
                </div>
                <div class="mt-md-0 mt-2 text-right">
                  <h4
                    class="invoice-title"
                    style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                  >
                    PRF-{{ purchaseRequest.stringID }}
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Requested By:</span> {{ requesterName }}, {{ requesterDepartment }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  v-if="pg == 1"
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    Requester Details
                  </h6>
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Entity / Company
                        </b-th>
                        <b-td>{{ resolveEntityName() }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Justification / Purpose of Purchase
                        </b-th>
                        <b-td>{{ purpose }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Required Delivery Date
                        </b-th>
                        <b-td>{{ deliveryDate }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Deliver To
                        </b-th>
                        <b-td>{{ resolveDeliverToName() }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Address
                        </b-th>
                        <b-td>{{ address }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Additional Remarks
                        </b-th>
                        <b-td
                          id="remarksFieldMain"
                          class="text-pre"
                          :class="{ 'fs-7': lowFont }"
                        >{{ remarks }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col
                  cols="12"
                  class="p-0 item__description_table"
                >
                  <!-- Invoice Description: Table -->
                  <!-- <b-table-lite
                    responsive
                    :items="chunksOfItemRequests[pg - 1]"
                    :fields="['no', { key: 'itemsMaster', label: 'item description' }, { key: 'quantity', label: 'QTY' },
                              { key: 'unit', label: 'Unit' }, { key: 'totalCost', label: 'Est. total cost (S$)' }]"
                  >
                    <template #cell(no)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(itemsMaster)="data">
                      <b-card-text class="font-weight-bold mb-25">
                        {{ data.item.itemsMaster }}
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                        {{ data.item.description }}
                      </b-card-text>
                    </template>
                  </b-table-lite> -->
                  <table
                    v-if="chunksOfItemRequests[pg - 1] && chunksOfItemRequests[pg - 1].length"
                    style="page-break-after: auto;"
                    class="pdf__table"
                  >
                    <thead style="display: table-header-group;">
                      <tr>
                        <th>No</th>
                        <th>item description</th>
                        <th>QTY</th>
                        <th>Unit</th>
                        <th>Est. total cost (S$)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in chunksOfItemRequests[pg - 1]"
                        :key="index"
                        style="page-break-inside: avoid; page-break-after: auto;"
                      >
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ resolveItemCounter(pg, index) }}
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto; width: 500px;">
                          <div style="page-break-inside: avoid; page-break-after: auto;">
                            <strong>{{ item.itemsMaster }}</strong>
                            <br>
                            <span class="text-pre">{{ item.description }}</span>
                          </div>
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ item.quantity }}
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ item.unit }}
                        </td>
                        <td style="page-break-inside: avoid; page-break-after: auto;">
                          {{ numberFormat(item.totalCost) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card-body>

            <div
              v-if="pg == pdfTotalPage"
              style="position: relative; bottom: 0; width: 100%;"
            >
              <div :style="{ height: gapHeight+'px' }" />
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="4"
                    md="4"
                    class="mt-md-0 mt-3"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">1. Requested By</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75">
                        <span class="semi-Bold">{{ requesterName }}</span><br>{{ requesterDepartment }}
                      </p>
                    </b-card-text>
                  </b-col>
                  <b-col
                    cols="4"
                    md="4"
                    class="mt-md-0 mt-3"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">2. Confirmed By</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75">
                        <span class="semi-Bold">{{ resolveConfirmedByName() }}</span><br>{{ resolveConfirmedByDepartment() }}
                      </p>
                    </b-card-text>
                  </b-col>
                  <b-col
                    cols="4"
                    md="4"
                    class="mt-md-0 mt-3"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">3. Approved By</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75">
                        <span class="semi-Bold">{{ resolveApprovedByName() }}</span><br>{{ resolveApprovedByDepartment() }}
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </div>
          <!-- <div
            v-if="pg != pdfTotalPage"
            class="html2pdf__page-break"
          /> -->
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div
              id="main-header"
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="width:60px; height:60px;"
                  :src="logoSrc"
                  alt="Logo"
                >
              </div>
              <div
                class="px-2"
                style="min-width: fit-content; width: fit-content;"
              >
                <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                  Purchase Requisition form (PRF)
                </h4>
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  PRF-{{ purchaseRequest.stringID }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Requested By:</span> {{ requesterName }}, {{ requesterDepartment }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr
            class="invoice-spacing"
            style="margin-top:25px!important"
          >

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0 main__body"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                id="requester-header"
                cols="12"
                xl="12"
                class="p-0"
              >
                <h6 class="mb-2">
                  Requester Details
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Entity / Company
                      </b-th>
                      <b-td>{{ resolveEntityName() }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Justification / Purpose of Purchase
                      </b-th>
                      <b-td>{{ purpose }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Required Delivery Date
                      </b-th>
                      <b-td>{{ deliveryDate }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Deliver To
                      </b-th>
                      <b-td>{{ resolveDeliverToName() }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Address
                      </b-th>
                      <b-td>{{ address }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Additional Remarks
                      </b-th>
                      <b-td
                        id="remarksField"
                        class="text-pre"
                      >
                        {{ remarks }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col
                id="content-div"
                cols="12"
                class="p-0 item__description_table"
              >
                <!-- Invoice Description: Table -->
                <!-- <b-table-lite
                  responsive
                  :items="chunkItemRequests"
                  :fields="['no', { key: 'itemsMaster', label: 'item description' }, { key: 'quantity', label: 'QTY' },
                            { key: 'unit', label: 'Unit' }, { key: 'totalCost', label: 'Est. total cost (S$)' }]"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(itemsMaster)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      {{ data.item.itemsMaster }}
                    </b-card-text>
                    <b-card-text class="text-nowrap">
                      {{ data.item.description }}
                    </b-card-text>
                  </template>
                </b-table-lite> -->
                <table-content
                  :el-height.sync="elHeight"
                  :chunk-item-requests="chunkItemRequests"
                />
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0 footer__area">
            <b-row
              id="footer-div"
            >

              <!-- Col: Sales Persion -->
              <b-col
                cols="4"
                md="4"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">1. Requested By</span>
                  <div class="sign-box">
                    <p>Pending Signature</p>
                  </div>
                  <p class="ml-75">
                    <span class="semi-Bold">{{ requesterName }}</span><br>{{ requesterDepartment }}
                  </p>
                </b-card-text>
              </b-col>
              <b-col
                cols="4"
                md="4"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">2. Confirmed By</span>
                  <div class="sign-box">
                    <p>Pending Signature</p>
                  </div>
                  <p class="ml-75">
                    <span class="semi-Bold">{{ resolveConfirmedByName() }}</span><br>{{ resolveConfirmedByDepartment() }}
                  </p>
                </b-card-text>
              </b-col>
              <b-col
                cols="4"
                md="4"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">3. Approved By</span>
                  <div class="sign-box">
                    <p>Pending Signature</p>
                  </div>
                  <p class="ml-75">
                    <span class="semi-Bold">{{ resolveApprovedByName() }}</span><br>{{ resolveApprovedByDepartment() }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-card>
    </div>
    <div v-show="prfPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="breadcrumbs-top">
                <b-col cols="12">
                  <h1 class="custom-header-title">
                    Preview Purchase Request
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <section class="invoice-preview-wrapper">
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <div>
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                        />
                        <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ attachments ? ((attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                      </template>
                      <b-dropdown-item @click="changeFileIndex(1)">
                        PRF-{{ purchaseRequest.stringID }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="(file, key) in attachments"
                        :key="key"
                        @click="changeFileIndex(key + 2)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <p
                      v-show="currentFileIndex == 1"
                      class="mt-1"
                    >
                      Purchase Requisition Form (PRF)
                    </p>
                    <p
                      v-show="currentFileIndex != 1"
                      class="mt-1"
                    >
                      {{ attachments[currentFileIndex - 2] ? attachments[currentFileIndex - 2].description : '' }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == (attachments ? (attachments.length + 1) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <!-- <iframe
              id="docpdf"
              width="100%"
              height="1145"
              style="background: #9FB8D8; padding: 20px;"
            /> -->
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <div v-show="showAttachment">
                <iframe
                  v-show="showPDF"
                  id="attachmentpdf"
                  width="100%"
                  height="1145"
                />
                <b-img
                  v-show="!showPDF"
                  :src="imageSrc"
                  fluid-grow
                  alt="Fluid-grow image"
                />
              </div>
              <div v-show="!showAttachment">
                <iframe
                  id="docpdf"
                  width="100%"
                  height="1145"
                />
              </div>
            </b-card>
            <!-- <PDFJSViewer
              :path="path"
              :file-name="fileName"
            /> -->
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            v-if="user._id == (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : '')"
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>

              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="GridIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">Approval Required</span>
                  Ready to Submit?
                </h3>
              </template>

              <b-card-text class="mb-0">
                <span>
                  If the information on this Purchase Request Form is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ resolveConfirmedByName() }}) for signing.
                </span>
              </b-card-text>

              <b-form-checkbox
                v-model="secondAgree"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                I confirm that all information is accurate on this PRF.
              </b-form-checkbox>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="secondAgree == ''"
                @click="finalConfirmation()"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Sign and Submit PRF</span>
              </b-button>

              <!-- Button: DOwnload -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="enableEdit()"
              >
                Back to Editing
              </b-button>

              <!-- Button: Add Payment -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                :disabled="user._id != (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : '')"
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </b-card>
          </b-col>
          <b-col
            v-else
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>

              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="SendIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  Send Back To Requester
                </h3>
              </template>

              <b-card-text class="mb-0">
                <b-form-textarea
                  id="h-purchaseRequest-cpUser"
                  v-model="comment"
                  trim
                  placeholder="Share with this requester the edits you have made, and why. This remark will be added to the comments and is visible to all users who have access to this Purchase Request."
                />
              </b-card-text>

              <p>By sending this PR back to requester, all signatures will be cleared and this PR will need to be re-approved.</p>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="comment == ''"
                @click="sendBackToRequester()"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Send Back to Requester</span>
              </b-button>

              <!-- Button: DOwnload -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="enableEdit()"
              >
                Back to Editing
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>
    <div v-show="!prfPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="create-edit-form">
            <b-col cols="12">
              <h1 class="custom-header-title">
                Editing Purchase Request: PRF-{{ purchaseRequest.stringID }}
              </h1>
              <p class="log_info">
                Created by {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }} on {{ dateFormatWithTime(purchaseRequest.createdAt) }}<br>Last updated on {{ dateFormatWithTime(purchaseRequest.updatedAt) }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="serviceFormEditForm"
          #default="{invalid}"
        >
          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 1</span>
                {{ user._id == purchaseRequest.createdBy ? 'Your' : 'Requester' }} Details
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Requester Name*"
                  label-for="h-booking-requesterName"
                  label-cols-md="4"
                >
                  <validation-provider
                    name="Requester Name"
                    vid="requesterName"
                  >
                    <b-form-input
                      id="h-booking-requesterName"
                      v-model="requesterName"
                      name="requesterName"
                      readonly
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  vid="date"
                  rules="required"
                >
                  <b-form-group
                    label="Required Delivery Date*"
                    label-for="h-event-deliveryDate"
                    label-cols-md="4"
                  >
                    <b-input-group>
                      <flat-pickr
                        id="h-event-deliveryDate"
                        v-model="deliveryDate"
                        class="form-control flat-pickr-group"
                        placeholder="Required Delivery Date"
                        :config="flatPickrConfig"
                        @input="deliveryDateValidation == true ? deliveryDateValidation = false : null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="CalendarIcon"
                          class="cursor-pointer"
                          data-toggle
                          size="18"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="deliveryDateValidation"
                      class="text-danger"
                    >
                      {{ deliveryDateError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <b-form-group
                  label="Requester Department*"
                  label-for="h-booking-requesterDepartment"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Requester Department"
                    vid="requesterDepartment"
                    rules="required"
                  >
                    <b-form-input
                      v-if="!choseDepartment"
                      id="h-booking-requesterDepartment"
                      v-model="requesterDepartment"
                      name="requesterDepartment"
                      readonly
                    />

                    <v-select
                      v-else
                      id="h-booking-requesterDepartment"
                      v-model="department"
                      label="name"
                      placeholder="Choose Department"
                      :options="departmentOptions"
                      :reduce="title => title._id"
                      :clearable="false"
                      @input="getRequestorDepartment()"
                    />

                    <small
                      v-if="errors.length"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  name="Deliver To"
                  vid="deliverTo"
                  rules=""
                >
                  <b-form-group
                    label-cols-md="4"
                    label-for="h-booking-deliverTo"
                    label="Deliver To*"
                  >
                    <!-- <v-select
                      id="h-bookings-deliverTo"
                      v-model="deliverTo"
                      label="address"
                      placeholder="Select an option"
                      :options="addressOptions"
                      :reduce="adrs => adrs._id"
                      :clearable="false"
                      @input="setAddress"
                    > -->
                    <!-- eslint-disable -->
                      <!-- <template #option="{ businessEntity, address }">
                        <span class="">{{ businessEntity.name ? businessEntity.name : '' }} - {{ address }}</span>
                      </template>

                      <template #selected-option="{ businessEntity, address }">
                        <span class="">{{ businessEntity ? businessEntity.name : '' }} - {{ address }}</span>
                      </template> -->
                      <!-- eslint-enable -->
                    <!-- </v-select> -->
                    <div class="demo-inline-spacing roster_group_dropdown deliverToDropdown dropdown-with-icon">
                      <span>{{ resolveDeliverToName() }}</span>
                      <b-dropdown
                        id="dropdown-form-purchase-request"
                        ref="filter_dropdown_all_purchase_request"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        right
                        variant="link"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="FileTextIcon"
                            size="20"
                            class="align-middle mr-50"
                          />
                        </template>
                        <h4>Select from Address Book</h4>
                        <p>Pre-fill your address fields by selecting one of the saved addresses below.</p>
                        <h5>Addresses</h5>
                        <b-dropdown-item
                          v-for="(adrs, key) in addressOptions"
                          :key="key"
                          @click="setAddress(adrs)"
                        >
                          <div>
                            <span class="com__name">{{ adrs.name }}</span>
                            <span>{{ adrs.address }}</span>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  name="Justification / Purpose"
                  vid="purpose"
                  rules="required"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-for="h-booking-purpose"
                    label="Justification / Purpose*"
                  >
                    <!-- <v-select
                      id="h-bookings-purpose"
                      v-model="purpose"
                      label="title"
                      placeholder="Select an option"
                      :options="purposeOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                    /> -->
                    <vue-autosuggest
                      v-model="purpose"
                      :suggestions="filteredPurposeOptions"
                      :get-suggestion-value="getPurposeSuggestionValue"
                      :input-props="{id: 'autosuggest__purpose_input', class: 'form-control', placeholder: 'Type a purpose'}"
                      @selected="setPurpose"
                      @input="suggestOnInputChangePurpose"
                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item }}</span>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6">
                <b-form-group
                  label="Address"
                  label-for="h-booking-address"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    vid="address"
                    rules="required"
                  >
                    <b-form-textarea
                      id="h-booking-address"
                      v-model="address"
                      trim
                      placeholder="Address"
                      :state="addressValidation == true ? addressValidation = false : null"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="addressValidation"
                      class="text-danger"
                    >
                      {{ addressError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 2</span>
                Type of Request
              </h3>
            </template>
            <b-row>

              <b-col
                cols="12"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-radio-group
                    id="requestType"
                    v-model="requestType"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio w-100 text__left"
                  >
                    <b-form-radio
                      v-for="(option, index) in requestTypeOptions"
                      :key="index"
                      :value="option.value"
                      class="mb-50 p-2"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <feather-icon
                        v-if="requestType === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <br>

                  <small
                    v-if="requestTypeValidation"
                    class="text-danger"
                  >
                    {{ requestTypeError }}
                  </small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 3</span>
                Entity / Company
              </h3>
            </template>
            <b-row>

              <b-col
                cols="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-radio-group
                      id="company"
                      v-model="company"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio display-block-checkbox w-100 text__left"
                      @change="updateLogo"
                    >
                      <b-form-radio
                        v-for="(option, index) in businessEntityOptions"
                        :key="index"
                        :value="option._id"
                        class="mb-50 business-entity-box"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <feather-icon
                          v-if="company === option._id"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        {{ option.name }}
                        <br>
                        <span class="option-text-black">{{ option.addresses.length ? option.addresses[0].address : '' }}</span>
                      </b-form-radio>
                    </b-form-radio-group>
                    <br>

                    <small
                      v-if="companyValidation"
                      class="text-danger"
                    >
                      {{ companyError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            ref="repeatCard"
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 4</span>
                Item(s) to Purchase
              </h3>
            </template>
            <b-row
              v-for="(item, index) in itemRequests"
              :id="'itemRequests' + index"
              :key="index"
              ref="row"
              class="itemRequests_fields"
            >
              <!-- Item Name -->
              <b-col class="item_field">
                <validation-provider
                  #default="{ errors }"
                  name="item"
                  rules="required"
                >
                  <b-form-group
                    label="Item*"
                    :label-for="'autosuggest__input' + index"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <!-- <v-select
                      :id="'autosuggest__input' + index"
                      v-model="item.itemsMaster"
                      label="itemNo"
                      placeholder="Select an option"
                      :options="itemsMasterOptions"
                      :reduce="itemNo => itemNo._id"
                      :clearable="false"
                      @input="changeItemsMaster(index)"
                    /> -->

                    <!-- <vue-autosuggest
                      v-model="item.itemsMaster"
                      :suggestions="filteredOptions"
                      :get-suggestion-value="getSuggestionValue"
                      :input-props="{id: `autosuggest__input${index}`, class: 'form-control', placeholder: 'Type an item name'}"
                      @selected="(...args) => setMasterItem(...args, index)"
                      @input="suggestOnInputChange"
                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item.description }}</span>
                      </template>
                    </vue-autosuggest> -->

                    <div
                      class="d-flex justify-content-between search-bar"
                    >
                      <b-form-input
                        :id="'search_name'+index"
                        v-model="item.searchInput"
                        name="name"
                        placeholder="Type an item name"
                        autocomplete="off"
                        @keyup="submitSearchInput(index)"
                        @blur="closeSuggestionBox(index)"
                      />
                    </div>
                    <div v-show="item.searchInputResult">
                      <div
                        class="search-results mt-50"
                      >
                        <div>
                          <b-list-group
                            v-if="item.storeItemSearchInputData.length"
                            flush
                          >
                            <span class="found-text-block">Inventory Items</span>
                            <b-list-group-item
                              v-for="(storeItem, sIndex) in item.storeItemSearchInputData"
                              :key="sIndex"
                              class="cursor-pointer"
                              @mousedown="addSearchInputStoreItem(index, sIndex, storeItem._id)"
                            >
                              <div class="d-flex">
                                <b-img
                                  class="item-img-block"
                                  :src="storeItem.image ? storeItem.image : defaultImage"
                                />
                                <div
                                  class="search-item-text-block"
                                >
                                  <h6>{{ storeItem.name }}</h6>
                                  <small class="text-muted">{{ storeItem.sku }}</small>
                                </div>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                          <b-list-group
                            v-if="item.masterItemSearchInputData.length"
                            flush
                            class="mt-1"
                          >
                            <span class="found-text-block">Suggestions from Purchasing</span>
                            <b-list-group-item
                              v-for="(masterItem, mIndex) in item.masterItemSearchInputData"
                              :key="mIndex"
                              class="cursor-pointer"
                              @mousedown="addSearchInputMasterItem(index, mIndex, masterItem._id)"
                            >
                              <h6 style="margin-left: 5px;">
                                {{ masterItem.description }}
                              </h6>
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                      </div>
                    </div>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col class="description_field">
                <b-form-group
                  label="SKU"
                  :label-for="'sku' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sku"
                    :vid="'sku' + index"
                  >
                    <b-form-input
                      :id="'sku' + index"
                      v-model="item.sku"
                      placeholder=""
                      :state="(errors.length > 0) ? false : null"
                      name="sku"
                      readonly
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Cost -->
              <b-col class="description_field">
                <b-form-group
                  label="Description"
                  :label-for="'description' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    vid="description"
                  >
                    <b-form-textarea
                      :id="'description' + index"
                      v-model="item.description"
                      class="textarea-height"
                      placeholder="E.g. model number, product specifications."
                      :state="(errors.length > 0) ? false : null"
                      name="description"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Quantity -->
              <b-col class="quantity_field">
                <!-- <validation-provider
                  #default="{ errors }"
                  name="quantity"
                  rules="required"
                >
                  <b-form-group
                    label="Quantity*"
                    :label-for="'quantity' + index"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <v-select
                      :id="'quantity' + index"
                      v-model="item.quantity"
                      placeholder=""
                      :options="quantities"
                      :clearable="false"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider> -->
                <b-form-group
                  label="Quantity*"
                  :label-for="'quantity' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Quantity"
                    vid="quantity"
                    rules="required|float"
                  >
                    <b-form-input
                      :id="'quantity' + index"
                      v-model="item.quantity"
                      placeholder="0"
                      :state="(errors.length > 0) ? false : null"
                      name="quantity"
                      @keypress="validateNumberInput"
                      @paste="validateNumberPaste"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Cost -->
              <b-col class="unit_field">
                <validation-provider
                  #default="{ errors }"
                  name="unit"
                  rules="required"
                >
                  <b-form-group
                    label="Unit*"
                    :label-for="'unit' + index"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <vue-autosuggest
                      v-model="item.unit"
                      :suggestions="filteredUnitOptions"
                      :get-suggestion-value="getUnitSuggestionValue"
                      :input-props="{id: `autosuggest__unit_input${index}`, class: 'form-control', placeholder: 'E.g. kg'}"
                      @selected="(...args) => setUnit(...args, index)"
                      @input="suggestOnInputChangeUnit"
                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item }}</span>
                      </template>
                    </vue-autosuggest>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col class="totalCost_field">
                <b-form-group
                  label="Est. Total Cost(S$)"
                  :label-for="'totalCost' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Est. Total Cost(S$)"
                    vid="totalCost"
                    rules="float"
                  >
                    <b-form-input
                      :id="'totalCost' + index"
                      v-model="item.totalCost"
                      placeholder="0.00"
                      :state="(errors.length > 0) ? false : null"
                      name="totalCost"
                      @keypress="validateNumberInput"
                      @paste="validateNumberPaste"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                class="remove__field"
              >
                <b-button
                  variant="flat-danger"
                  class="mt-0 mt-md-2 primary-button-with-background no-background"
                  type="button"
                  @click="removePurchaseRequest(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>
            </b-row>
            <div class="add_item">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPurchaseRequest"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Item</span>
              </b-button>
            </div>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 5</span>
                Supporting Documents, Files and Images
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-dropzone
                  id="event-images"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-files-added="processFile"
                  @vdropzone-removed-file="fileRemoved"
                  @vdropzone-error="handleError"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drop files here or click to upload
                    </h3>
                    <div class="subtitle">
                      File should not bigger than 20 mb. JPEG, PNG, or PDF only
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mt-2"
                    >
                      Upload file
                    </b-button>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 6</span>
                Additional Remarks
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label=""
                  label-for="remarks"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    vid="remarks"
                  >
                    <b-form-textarea
                      id="remarks"
                      v-model="remarks"
                      rows="4"
                      placeholder="Any other information for the purchasing team regarding this PRF"
                      :state="(errors.length > 0) ? false : null"
                      name="remarks"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 7</span>
                Signatories
              </h3>
            </template>
            <b-row
              ref="row"
            >
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Requested By"
                  vid="requestedBy"
                  rules="required"
                >
                  <b-form-group
                    label="Requested By*"
                    label-for="h-activity-type-requestedBy"
                    label-cols-md="5"
                    :state="(errors.length > 0 || requestedByValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-requestedBy"
                      v-model="requestedBy"
                      label="name"
                      placeholder="Select an option"
                      :options="userOptions"
                      :reduce="name => name._id"
                      :clearable="false"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="requestedByValidation"
                      class="text-danger"
                    >
                      {{ requestedByError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="md-6" />
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Confirmed By"
                  vid="confirmedBy"
                  rules="required"
                >
                  <b-form-group
                    label="Confirmed By*"
                    label-for="h-activity-type-confirmedBy"
                    label-cols-md="5"
                    :state="(errors.length > 0 || confirmedByValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-confirmedBy"
                      v-model="confirmedBy"
                      label="name"
                      placeholder="Select an option"
                      :options="userOptions"
                      :reduce="name => name._id"
                      :clearable="false"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="confirmedByValidation"
                      class="text-danger"
                    >
                      {{ confirmedByError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6" />
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="approved By"
                  vid="approvedBy"
                  rules="required"
                >
                  <b-form-group
                    label="Approved By*"
                    label-for="h-activity-type-approvedBy"
                    label-cols-md="5"
                    :state="(errors.length > 0 || approvedByValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-approvedBy"
                      v-model="approvedBy"
                      label="name"
                      placeholder="Select an option"
                      :options="userOptions"
                      :reduce="name => name._id"
                      :clearable="false"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="approvedByValidation"
                      class="text-danger"
                    >
                      {{ approvedByError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="md-6" />
            </b-row>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :disabled="invalid || user._id != (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : '') || !deliverTo"
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                :disabled="invalid || !deliverTo"
                @click="enablePreview()"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Preview PRF</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, VBToggle,
  BFormRadioGroup, BFormRadio, BInputGroup, BInputGroupAppend, BCardBody, BCardText, BImg,
  BTableSimple, BTbody, BTr, BTh, BTd, BFormCheckbox, BDropdown, BDropdownItem, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vue2Dropzone from 'vue2-dropzone'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store/index'
// import { jsPDF } from 'jspdf'
// import html2canvas from 'html2canvas'
import html2pdf from 'html2pdf.js'

// import PDFJSViewer from '../../components/PDFJSViewer.vue'

import TableContent from './TableContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BFormRadioGroup,
    BFormRadio,
    BCardBody,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BImg,
    vSelect,
    VueAutosuggest,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    TableContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      purchaseRequest: {},
      prfPreview: false,

      editedBy: store.state.auth.userData._id,
      user: store.state.auth.userData,
      requesterName: '',
      deliveryDate: '',
      requesterDepartment: '',
      choseDepartment: false,
      department: '',
      departmentOptions: [],
      deliverTo: '',
      purpose: '',
      address: '',
      remarks: '',
      requestType: 'Purchase Requisition Form (PRF)',
      saveType: 'preview',
      company: '',
      comment: '',
      path: '/lib/pdfjs/web/viewer.html',
      pdfData: '',
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      secondAgree: '',
      showPDF: true,
      showAttachment: false,
      selectedFileName: 'PRF-000055',
      currentFileIndex: 1,
      imageSrc: '',

      requestedBy: '',
      confirmedBy: '',
      approvedBy: '',
      signatoryOption: 1,
      signatoryOptionOld: 1,
      attachments: [],
      itemsMasterOptions: [],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      itemRequests: [{
        searchInput: '',
        searchInputResult: false,
        storeItemSearchInputData: [],
        masterItemSearchInputData: [],
        searchResultNotFound: false,
        inventoryID: '',
        sku: '',
        itemsMaster: '',
        description: '',
        quantity: '1',
        unit: 'Piece(s)',
        totalCost: '',
      }],
      storeItemData: [],
      masterItemData: [],
      filteredOptions: [],
      filteredUnitOptions: [],
      filteredPurposeOptions: [],
      deliveryDateError: 'Valid Delivery Date is required',
      deliveryDateValidation: false,
      deliverToError: 'Valid Deliver To is required',
      deliverToValidation: false,
      purposeError: 'Valid Justification / Purpose is required',
      purposeValidation: false,
      addressError: 'Valid Address is required',
      addressValidation: false,
      requestTypeError: 'Valid Type of Request is required',
      requestTypeValidation: false,
      confirmedByError: 'Valid user is required',
      confirmedByValidation: false,
      approvedByError: 'Valid user is required',
      approvedByValidation: false,
      requestedByError: 'Valid user is required',
      requestedByValidation: false,
      userOptions: [],
      vendorOptions: [],
      companyOptions: [
        { text: 'Paramount Zen Pte. Ltd.', value: 'Paramount Zen Pte. Ltd.', description: '1 One Raffles Place #19-01, Office Tower One, Singapore 048616' },
      ],
      addressOptions: [],
      businessEntityOptions: [],
      unitOptions: process.env.VUE_APP_UNIT_LIST.split(','),
      signatoryAssigns: [],
      signatoryAssignsError: 'Valid Signatory is required',
      signatoryAssignsValidation: false,
      signatoryOptionError: 'Valid Number of Signatory is required',
      signatoryOptionValidation: false,
      companyError: 'Valid Entity/Company is required',
      companyValidation: false,
      optionsChekcbox: [
        { text: 'ISO 9001 Quality Management', value: 'ISO 9001 Quality Management' },
        { text: 'ISO 14001 Environment Management', value: 'ISO 14001 Environment Management' },
        { text: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management', value: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management' },
        { text: 'No Certificate', value: 'No Certificate' },
        { text: 'Others', value: 'Others' },
      ],
      requestTypeOptions: [
        { text: 'Purchase Requisition Form (PRF)', value: 'Purchase Requisition Form (PRF)' },
        { text: 'Capital Expenditure Requisition Form (CAPEX)', value: 'Capital Expenditure Requisition Form (CAPEX)' },
      ],
      quantities: [],
      signatoryOptions: [
        { title: '1', code: '1' },
        { title: '2', code: '2' },
        { title: '3', code: '3' },
        { title: '4', code: '4' },
        { title: '5', code: '5' },
        { title: '6', code: '6' },
        { title: '7', code: '7' },
        { title: '8', code: '8' },
        { title: '9', code: '9' },
        { title: '10', code: '10' },
      ],
      purposeOptions: ['Daily Use', 'Kitchen Use', 'Recurring', 'Event Item'],
      lqas: [
        { title: '1. Prepared by', code: '1. Prepared by' },
        { title: '2. Requested by', code: '2. Requested by' },
        { title: '3. Verified by', code: '3. Verified by' },
        { title: '4. Recommended by', code: '4. Recommended by' },
        { title: '5. Agreed by', code: '5. Agreed by' },
        { title: '6. Certified by', code: '6. Certified by' },
        { title: '7. Confirmed by', code: '7. Confirmed by' },
        { title: '8. Supported by', code: '8. Supported by' },
        { title: '9. Validated by', code: '9. Validated by' },
        { title: '10. Approved by', code: '10. Approved by' },
      ],
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigWithTime: {
        wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today', disableMobile: true,
      },
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      logoSrc: '/nirvana-memorial-garden-logo.png',

      // validation rules
      required,
      lowFont: false,
    }
  },

  async created() {
    // for (let i = 1; i <= 100; i += 1) {
    //   this.quantities[i] = i
    // }
    await this.$http.get('purchase/purchase-request/create/extra-options')
      .then(response => {
        this.itemsMasterOptions = response.data.itemsMasterOptions ?? []
        this.masterItemData = response.data.itemsMasterOptions
        this.storeItemData = response.data.inventoryItems
        // this.addressOptions = response.data.addressOptions ?? []
        this.businessEntityOptions = response.data.businessEntityOptions ?? []
        this.userOptions = response.data.userOptions ?? []
        this.businessEntityOptions.forEach(businessEntity => {
          if (businessEntity.addresses.length) {
            businessEntity.addresses.forEach(address => {
              const adrs = { ...businessEntity, address: address.address }
              this.addressOptions.push(adrs)
            })
          } else {
            const adrs = { ...businessEntity, address: '' }
            this.addressOptions.push(adrs)
          }
        })
        this.updateLogo()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get(`purchase/purchase-request/${this.$route.params.id}`)
      .then(response => {
        this.purchaseRequest = response.data.data
        this.deliveryDate = this.dateFormat(response.data.data.deliveryDate) || ''

        this.deliverTo = response.data.data.deliverTo || ''
        this.purpose = response.data.data.purpose || ''
        this.address = response.data.data.address || ''
        this.remarks = response.data.data.remarks || ''
        this.requestType = response.data.data.requestType || ''
        this.company = response.data.data.company || ''
        this.certificates = response.data.data.certificates || ''
        // this.itemRequests = response.data.prf.items
        this.requesterName = response.data.data.createdBy.name || ''
        this.requesterName = response.data.data.createdBy.name || ''
        const deptArray = response.data.data.createdBy.department
        this.department = response.data.data.department._id
        this.requesterDepartment = response.data.data.department.name
        if (deptArray.length) {
          if (deptArray.length === 1) {
            this.choseDepartment = false
          } else {
            this.departmentOptions = deptArray
            this.choseDepartment = true
          }
        }
        // this.requesterDepartment = (response.data.data.createdBy.department.length ? response.data.data.createdBy.department[0].name : '') || ''

        this.requestedBy = response.data.data.createdBy._id
        this.confirmedBy = response.data.prf.confirmedBy
        this.approvedBy = response.data.prf.approvedBy
        this.updateLogo()

        this.itemRequests = []
        response.data.prf.items.forEach(selectedItem => {
          // eslint-disable-next-line eqeqeq
          const item = this.storeItemData.find(itemObj => itemObj._id == selectedItem.inventoryID)
          if (item) {
            this.itemRequests.push({
              searchInput: item.name,
              searchInputResult: false,
              storeItemSearchInputData: [],
              masterItemSearchInputData: [],
              searchResultNotFound: false,
              inventoryID: item._id,
              sku: item.sku,
              itemsMaster: item.name,
              description: selectedItem.description,
              quantity: selectedItem.quantity,
              unit: selectedItem.unit,
              totalCost: selectedItem.totalCost,
            })
          } else {
            this.itemRequests.push({
              searchInput: selectedItem.itemsMaster,
              searchInputResult: false,
              storeItemSearchInputData: [],
              masterItemSearchInputData: [],
              searchResultNotFound: false,
              inventoryID: '',
              sku: '',
              itemsMaster: selectedItem.itemsMaster,
              description: selectedItem.description,
              quantity: selectedItem.quantity,
              unit: selectedItem.unit,
              totalCost: selectedItem.totalCost,
            })
          }
        })

        response.data.data.attachments.forEach(arrayItem => {
          const file = {
            size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, description: arrayItem.description, type: arrayItem.type,
          }
          this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
          this.attachments.push(arrayItem)
        })

        this.selectedFileName = `PRF-${this.purchaseRequest.stringID}`

        // response.data.purchaseRequestItems.forEach(arrayItem => {
        //   this.itemRequests.push({
        //
        //     itemsMaster: arrayItem.itemsMaster._id,
        //     description: arrayItem.itemsMaster.description,
        //     unit: arrayItem.itemsMaster.unit,
        //     quantity: arrayItem.quantity,
        //     totalCost: arrayItem.totalCost,
        //
        //     id: arrayItem._id,
        //   })
        // })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    const bcLength = store.state.breadcrumb.breadcrumbs.length
    if (bcLength > 2) {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
    }
    next()
  },

  // destroyed() {
  //   window.removeEventListener('resize', this.initTrHeight)
  // },
  methods: {
    updateLogo() {
      const businessEntity = this.businessEntityOptions.find(o => o._id === this.company)
      if (businessEntity && businessEntity.logo) {
        this.logoSrc = businessEntity.logo
      }
    },
    getRequestorDepartment() {
      // eslint-disable-next-line eqeqeq
      const dept = this.departmentOptions.find(o => o._id == this.department)
      if (dept) {
        this.requesterDepartment = dept.name
      }
    },
    saveAsDraft() {
      this.saveType = 'draft'
      this.submitForm()
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = `PRF-${this.purchaseRequest.stringID}`
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.attachments[key - 2].name
        if (this.attachments[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.attachments[key - 2].data)
          // }
          if (!this.attachments[key - 2].data.startsWith('http')) {
            const base64Raw = this.attachments[key - 2].data.split(',')[1]
            const pdfDataJS = this.base64ToUint8Array(base64Raw)
            const pdfViewerFrame2 = document.getElementById('attachmentpdf')
            // pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${pdfDataJS}`)
            // eslint-disable-next-line func-names
            pdfViewerFrame2.onload = () => {
              pdfViewerFrame2.contentWindow.PDFViewerApplication.open(pdfDataJS)
            }
            pdfViewerFrame2.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          } else {
            const pdfViewerFrame2 = document.getElementById('attachmentpdf')
            pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.attachments[key - 2].data}`)
          }
          this.showPDF = true
        } else {
          this.imageSrc = this.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfItemRequests[i].length
      }

      count += index + 1

      return count
    },
    async enablePreview() {
      const newMeta = {
        active: true,
        text: 'Preview',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()
      // start of pdf using jspdf only
      // eslint-disable-next-line new-cap
      // const pdf = new jsPDF('l', 'pt', 'a4')
      // console.log(pdf.getFontList())
      // pdf.setFont('Montserrat-Regular', 'normal')
      // pdf.setProperties({
      //   title: 'PRF-000055',
      // })
      // pdf.html(document.getElementById('pdfGenerateDiv'), {
      //   margin: [40, 60, 40, 60],
      //   callback(pdfr) {
      //     // pdfr.save('test.pdf')
      //     // console.log(pdfr.internal.getNumberOfPages())
      //     const totalPages = pdfr.internal.getNumberOfPages()
      //     // eslint-disable-next-line no-plusplus
      //     for (let i = 1; i <= totalPages; i++) {
      //       pdfr.setPage(i)
      //       pdfr.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 30)
      //     }
      //     vm.pdfData = pdfr.output('datauristring')
      //     const base64Raw = vm.pdfData.split(',')[1]
      //     const pdfDataJS = vm.base64ToUint8Array(base64Raw)
      //     const pdfViewerFrame = document.getElementById('docpdf')
      //     // eslint-disable-next-line func-names
      //     pdfViewerFrame.onload = () => {
      //       pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
      //     }
      //     pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
      //     vm.prfPreview = true
      //   },
      // })
      // end of pdf using jspdf only

      // start of pdf using html to canvas
      // const htmlWidth = document.getElementById('pdfGenerateDiv').clientWidth
      // const htmlHeight = document.getElementById('pdfGenerateDiv').offsetHeight
      // const topLeftMargin = 15
      // const pdfWidth = htmlWidth + (topLeftMargin * 2)
      // const pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2)
      // // const pdfHeight = 2480
      // const canvasImageWidth = htmlWidth
      // const canvasImageHeight = htmlHeight
      // const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1
      // html2canvas(document.getElementById('pdfGenerateDiv'), { allowTaint: true }).then(canvas => {
      //   canvas.getContext('2d')
      //   const imgData = canvas.toDataURL('image/jpeg', 1.0)
      //   // eslint-disable-next-line new-cap
      //   const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight])
      //   pdf.addImage(imgData, 'JPG', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight)
      //   // eslint-disable-next-line no-plusplus
      //   for (let i = 1; i <= totalPDFPages; i++) {
      //     pdf.addPage([pdfWidth, pdfHeight])
      //     pdf.addImage(imgData, 'JPG', topLeftMargin, -(pdfHeight * i) + (topLeftMargin * 4), canvasImageWidth, canvasImageHeight)
      //   }

      //   vm.pdfData = pdf.output('datauristring')
      //   const base64Raw = vm.pdfData.split(',')[1]
      //   const pdfDataJS = vm.base64ToUint8Array(base64Raw)
      //   const pdfViewerFrame = document.getElementById('docpdf')
      //   // eslint-disable-next-line func-names
      //   pdfViewerFrame.onload = () => {
      //     pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
      //   }
      //   pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
      //   vm.prfPreview = true
      // })
      // end of pdf using html to canvas

      // start of pdf using html2PDF package
      const element = document.getElementById('pdfGenerateDiv')
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          useCORS: true,
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'p' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS = vm.base64ToUint8Array(base64Raw)
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          vm.currentFileIndex = 1
          vm.selectedFileName = `PRF-${vm.purchaseRequest.stringID}`
          vm.showAttachment = false
          vm.prfPreview = true
          window.scrollTo(0, 0)
          store.commit('appConfig/UPDATE_LOADER', false)
        })
      // end of pdf using html2PDF package
      // this.pdfData = data
      // this.prfPreview = true
    },
    enableEdit() {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      this.prfPreview = false
    },
    resolveEntityName() {
      const entity = this.businessEntityOptions.find(o => o._id === this.company)
      if (entity) {
        return entity.name
      }

      return ''
    },
    resolveDeliverToName() {
      const businessEntity = this.businessEntityOptions.find(o => o._id === this.deliverTo)
      if (businessEntity) {
        return businessEntity.name
      }

      return ''
    },

    // resolveConfirmedBy() {
    //
    //   const user = this.userOptions.find(o => o._id === this.confirmedBy)
    //   if (user) {
    //     return (
    //       <div>
    //         <span>{user.name}</span>
    //         <br />
    //         <span>{user.department.name}</span>
    //       </div>
    //     )
    //   }

    //   return ''
    // },
    resolveConfirmedByName() {
      const user = this.userOptions.find(o => o._id === this.confirmedBy)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveConfirmedByDepartment() {
      const user = this.userOptions.find(o => o._id === this.confirmedBy)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    resolveApprovedByName() {
      const user = this.userOptions.find(o => o._id === this.approvedBy)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveApprovedByDepartment() {
      const user = this.userOptions.find(o => o._id === this.approvedBy)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 20 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    closeSuggestionBox(index) {
      this.itemRequests[index].searchInputResult = false
    },
    submitSearchInput(index) {
      // eslint-disable-next-line no-param-reassign, no-return-assign
      this.itemRequests.forEach(o => o.searchInputResult = false)
      this.itemRequests[index].storeItemSearchInputData = this.storeItemData.filter(item => item.deletedAt === null && (item.name.toLowerCase().includes(this.itemRequests[index].searchInput.toLowerCase()) || item.sku.toLowerCase().includes(this.itemRequests[index].searchInput.toLowerCase())))
      this.itemRequests[index].masterItemSearchInputData = this.masterItemData.filter(item => item.description.toLowerCase().includes(this.itemRequests[index].searchInput.toLowerCase()))
      if (this.itemRequests[index].searchInput !== '') {
        this.itemRequests[index].searchInputResult = true
      } else {
        this.itemRequests[index].searchInputResult = false
      }
      if (!this.itemRequests[index].storeItemSearchInputData.length && !this.itemRequests[index].masterItemSearchInputData.length) {
        this.itemRequests[index].searchResultNotFound = true
        this.itemRequests[index].inventoryID = ''
        this.itemRequests[index].sku = ''
        this.itemRequests[index].itemsMaster = this.itemRequests[index].searchInput
      } else {
        this.itemRequests[index].searchResultNotFound = false
      }
    },
    addSearchInputStoreItem(masterIndex, index, id) {
      if (this.storeItemData.length) {
        const item = this.storeItemData.find(storeItemObj => storeItemObj._id === id)
        if (this.itemRequests.length) {
          const foundItemId = this.itemRequests.findIndex(obj => obj.inventoryID === item._id)
          if (foundItemId !== -1) {
            if (foundItemId === masterIndex) {
              this.itemRequests[masterIndex].searchInput = item.name
              this.itemRequests[masterIndex].itemsMaster = item.name
              this.itemRequests[masterIndex].searchInputResult = false
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'This item is already added!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              this.itemRequests[masterIndex].searchInput = ''
              this.itemRequests[masterIndex].itemsMaster = ''
              this.itemRequests[masterIndex].inventoryID = ''
              this.itemRequests[masterIndex].sku = ''
              this.itemRequests[masterIndex].searchInputResult = false
            }
          } else {
            this.itemRequests[masterIndex].inventoryID = item._id
            this.itemRequests[masterIndex].sku = item.sku
            // this.itemRequests[masterIndex].inventoryID = item._id
            this.itemRequests[masterIndex].searchInput = item.name
            this.itemRequests[masterIndex].itemsMaster = item.name
            this.itemRequests[masterIndex].searchInputResult = false
          }
        } else {
          this.itemRequests[masterIndex].inventoryID = item._id
          this.itemRequests[masterIndex].sku = item.sku
          // this.itemRequests[masterIndex].inventoryID = item._id
          this.itemRequests[masterIndex].searchInput = item.name
          this.itemRequests[masterIndex].itemsMaster = item.name
          this.itemRequests[masterIndex].searchInputResult = false
        }
      }
    },
    addSearchInputMasterItem(masterIndex, index, id) {
      if (this.masterItemData.length) {
        const item = this.masterItemData.find(masterItemObj => masterItemObj._id === id)
        if (item) {
          this.itemRequests[masterIndex].inventoryID = ''
          this.itemRequests[masterIndex].sku = ''
          this.itemRequests[masterIndex].unit = item.unit
          this.itemRequests[masterIndex].searchInput = item.description
          this.itemRequests[masterIndex].itemsMaster = item.description
          this.itemRequests[masterIndex].searchInputResult = false
        }
      }
    },
    addPurchaseRequest() {
      this.itemRequests.push({
        searchInput: '',
        searchInputResult: false,
        storeItemSearchInputData: [],
        masterItemSearchInputData: [],
        searchResultNotFound: false,
        inventoryID: '',
        sku: '',
        itemsMaster: '',
        description: '',
        quantity: '1',
        unit: '',
        totalCost: 0,
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    async calculateTotalPage() {
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      this.pdfTotalPage = 0
      // const logoHeight = document.getElementById('main-header').offsetHeight
      // const requesterHeight = document.getElementById('requester-header').offsetHeight
      // const footerHeight = document.getElementById('footer-div').offsetHeight
      const logoHeight = 135
      let requesterHeight = document.getElementById('requester-header').offsetHeight
      if ((logoHeight + requesterHeight) > 800) {
        document.getElementById('remarksField').style.fontSize = '7px'
        this.lowFont = true
        await this.$nextTick()
        requesterHeight = document.getElementById('requester-header').offsetHeight
      }
      // footer height will be calculated based on assignee in other pdfs. for pr assignee is limited to 3
      const footerHeight = 210
      let contentHeight = 0
      let totalItems = this.itemRequests.length
      let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      if (totalItems > 10) {
        sliceEnd = 14
      }
      // while (totalItems > 0) {
      //   if (i === 0) {
      //     contentHeight = 970 - (logoHeight + requesterHeight + footerHeight)
      //   } else {
      //     contentHeight = 1040 - (logoHeight + footerHeight)
      //   }
      //   // console.log(contentHeight)
      //   this.chunkItemRequests = this.itemRequests.slice(sliceStart, sliceEnd)
      //   // this.$nextTick(() => {
      //   //   console.log(document.getElementById('content-div').offsetHeight)
      //   // })
      //   // eslint-disable-next-line no-await-in-loop
      //   await this.$nextTick()
      //   let tableHeight = document.getElementById('content-div').offsetHeight
      //   if (tableHeight > contentHeight) {
      //     contentHeight += footerHeight
      //   }
      //   // console.log(tableHeight)
      //   while (tableHeight > contentHeight) {
      //     sliceEnd -= 1
      //     // console.log(contentHeight, tableHeight)
      //     this.chunkItemRequests = this.itemRequests.slice(sliceStart, sliceEnd)
      //     // console.log(sliceStart, sliceEnd)
      //     // eslint-disable-next-line no-await-in-loop
      //     await this.$nextTick()
      //     tableHeight = document.getElementById('content-div').offsetHeight
      //     // console.log(tableHeight, contentHeight)
      //   }
      //   // console.log(tableHeight)
      //   // console.log(sliceEnd - sliceStart)
      //   // console.log(this.chunkItemRequests.length)
      //   this.chunksOfItemRequests.push(this.chunkItemRequests)
      //   this.pdfTotalPage += 1
      //   sliceStart = sliceEnd
      //   sliceEnd += 17
      //   // console.log(sliceStart, sliceEnd)
      //   i += 1
      //   totalItems -= this.chunkItemRequests.length
      // }
      while (totalItems > 0) {
        if (i === 0) {
          contentHeight = 1000 - (logoHeight + requesterHeight + footerHeight)
        } else {
          contentHeight = 1000 - (logoHeight + footerHeight)
        }
        // console.log(contentHeight)
        this.chunkItemRequests = this.itemRequests.slice(sliceStart, sliceEnd)
        // this.$nextTick(() => {
        //   console.log(document.getElementById('content-div').offsetHeight)
        // })
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
        }
        // // console.log(tableHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          // console.log(contentHeight, tableHeight)
          this.chunkItemRequests = this.itemRequests.slice(sliceStart, sliceEnd)
          // console.log(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight
          // console.log(tableHeight, contentHeight)
        }
        // console.log(tableHeight)
        // console.log(sliceEnd - sliceStart)
        // console.log(this.chunkItemRequests.length)
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }

      // if (this.pdfTotalPage === 1) {
      //   contentHeight = 940 - (logoHeight + requesterHeight + footerHeight)
      // } else {
      //   contentHeight = 940 - (logoHeight + footerHeight)
      // }
      // const finalHeight = document.getElementById('content-div').offsetHeight
      // if (finalHeight > contentHeight) {
      //   this.pdfTotalPage += 1
      // }
      let remainingHeight = 0
      const dHeight = 1080
      // if (this.isMobile()) {
      //   dHeight = 1120
      // }
      const finalDataHeight = document.getElementById('content-div').offsetHeight
      this.gapHeight = 0
      if (this.pdfTotalPage === 1) {
        remainingHeight = dHeight - (logoHeight + requesterHeight + footerHeight + finalDataHeight)
        if (remainingHeight > 0) {
          this.gapHeight = remainingHeight
        }
        if (finalDataHeight >= 650) {
          this.pdfTotalPage += 1
          this.gapHeight = dHeight - (logoHeight + footerHeight)
        }
      } else {
        remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
        if (remainingHeight > 0) {
          this.gapHeight = remainingHeight
        }
        if (finalDataHeight >= 850) {
          this.pdfTotalPage += 1
          this.gapHeight = dHeight - (logoHeight + footerHeight)
        }
      }
    },
    changeItemsMaster(index) {
      this.itemRequests[index].description = this.itemsMasterOptions[index].description
      this.itemRequests[index].unit = this.itemsMasterOptions[index].unit
    },
    removePurchaseRequest(index) {
      if (this.itemRequests.length > 1) {
        this.itemRequests.splice(index, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one row.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    setAddress(adrs) {
      this.deliverTo = adrs._id
      this.address = adrs.address
    },
    setMasterItem(suggestionItem, suggestionIndex, index) {
      if (suggestionIndex || suggestionIndex === 0) {
        this.itemRequests[index].itemsMaster = suggestionItem.item.description
        const masterItem = this.itemsMasterOptions.find(o => o.description === suggestionItem.item.description)
        if (masterItem) {
          this.itemRequests[index].unit = masterItem.unit
        }
      }
    },
    suggestOnInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.itemsMasterOptions.filter(item => item.description.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.description
    },
    suggestOnInputChangeUnit(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.unitOptions.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredUnitOptions = [{
        data: filteredData,
      }]
    },
    getUnitSuggestionValue(suggestion) {
      return suggestion.item
    },
    setUnit(suggestionItem, _suggestionIndex, index) {
      if (_suggestionIndex || _suggestionIndex === 0) {
        this.itemRequests[index].unit = suggestionItem.item
      }
    },
    suggestOnInputChangePurpose(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.purposeOptions.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredPurposeOptions = [{
        data: filteredData,
      }]
    },
    getPurposeSuggestionValue(suggestion) {
      return suggestion.item
    },
    setPurpose(suggestionItem, _suggestionIndex) {
      if (_suggestionIndex || _suggestionIndex === 0) {
        this.purpose = suggestionItem.item
      }
    },
    async changeSignatory() {
      if (this.signatoryOptionOld > this.signatoryOption) {
        await this.$nextTick()
        this.$swal({
          title: 'Are You Sure?',
          html: 'This action will remove the Jenny Chang for Singatories for this Purchase Request. All relevant parties will receive a notification.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: 'Yes, I’m sure.',
          customClass: {
            confirmButton: 'btn btn-danger ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.signatoryAssigns = []
              this.signatoryOptionOld = this.signatoryOption
            }
          })
      } else {
        this.signatoryAssigns = []
      }
    },

    finalConfirmation() {
      this.$swal({
        title: 'Submit This PRF?',
        html: `You are proceeding to sign this PRF. The PRF will then be sent to ${this.resolveConfirmedByName()} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
    },
    sendBackToRequester() {
      this.$swal({
        title: 'Send Back This PRF?',
        html: 'By sending this PR back to requester, all signatures will be cleared and this PR will need to be re-approved.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        imageWidth: 80,
        imageHeight: 80,
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
    },
    submitForm() {
      this.$refs.serviceFormEditForm.validate().then(success => {
        // setTimeout(() => {
        //   console.log(Object.values(this.$refs.serviceFormEditForm.errors).flat())
        // }, 100)
        if (success) {
          const formData = new FormData()

          formData.append('department', this.department)
          formData.append('url', window.location.origin)
          formData.append('deliveryDate', this.deliveryDate)
          formData.append('deliverTo', this.deliverTo)
          formData.append('purpose', this.purpose)
          formData.append('address', this.address)
          formData.append('remarks', this.remarks)
          formData.append('requestType', this.requestType)
          formData.append('company', this.company)
          formData.append('confirmedBy', this.confirmedBy)
          formData.append('approvedBy', this.approvedBy)
          const prItems = this.itemRequests.map(o => ({
            inventoryID: o.inventoryID,
            sku: o.sku,
            itemsMaster: o.searchInput,
            description: o.description,
            quantity: o.quantity,
            unit: o.unit,
            totalCost: o.totalCost,
          }))
          formData.append('itemRequests', JSON.stringify(prItems))
          formData.append('attachments', JSON.stringify(this.attachments))
          formData.append('status', this.saveType)
          formData.append('editedBy', this.editedBy)
          formData.append('comment', this.comment)

          this.$http.patch(`purchase/purchase-request/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                if (this.saveType === 'preview') {
                  if (this.user._id === (this.purchaseRequest.createdBy ? this.purchaseRequest.createdBy._id : '')) {
                    this.$swal({
                      title: 'Submitted for Approval',
                      html: 'This purchase request will be sent for verifying.<br>All relevant parties will receive a notification.',
                      // eslint-disable-next-line global-require
                      imageUrl: require('@/assets/images/icons/success.png'),
                      imageWidth: 80,
                      imageHeight: 80,
                      showCancelButton: false,
                      allowOutsideClick: false,
                      confirmButtonText: 'Okay',
                      customClass: {
                        confirmButton: 'btn btn-primary mr-1',
                        cancelButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                      .then(result => {
                        if (result.value) {
                          this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: response.data.data._id } })
                        }
                      })
                  } else {
                    this.$swal({
                      title: 'PRF updated to Pending Signatory',
                      html: `The requester (${this.purchaseRequest.createdBy ? this.purchaseRequest.createdBy.name : ''}) has been informed of your comments. You may verify this PRF after the requester re-submits this PRF.`,
                      // eslint-disable-next-line global-require
                      imageUrl: require('@/assets/images/icons/success.png'),
                      imageWidth: 80,
                      imageHeight: 80,
                      showCancelButton: false,
                      allowOutsideClick: false,
                      confirmButtonText: 'Okay',
                      customClass: {
                        confirmButton: 'btn btn-primary mr-1',
                        cancelButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                      .then(result => {
                        if (result.value) {
                          this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: response.data.data._id } })
                        }
                      })
                  }
                } else {
                  this.$swal({
                    title: 'Draft Saved',
                    html: 'You can continue editing this Purchase Request at a later time before sending it out for approval.',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: response.data.data._id } })
                      }
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'deliveryDate') {
                    this.deliveryDateError = validationError.msg
                    this.deliveryDateValidation = true
                  } else if (validationError.param === 'deliverTo') {
                    this.deliverToError = validationError.msg
                    this.deliverToValidation = true
                  } else if (validationError.param === 'purpose') {
                    this.purposeError = validationError.msg
                    this.purposeValidation = true
                  } else if (validationError.param === 'address') {
                    this.addressError = validationError.msg
                    this.addressValidation = true
                  } else if (validationError.param === 'requestType') {
                    this.requestTypeError = validationError.msg
                    this.requestTypeValidation = true
                  } else if (validationError.param === 'certificates') {
                    this.certificatesError = validationError.msg
                    this.certificatesValidation = true
                  } else if (validationError.param === 'signatoryOption') {
                    this.signatoryOptionError = validationError.msg
                    this.signatoryOptionValidation = true
                  } else if (validationError.param === 'company') {
                    this.companyError = validationError.msg
                    this.companyValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
</style>
